import "./Image.scss";
import { placeholderAsset } from "../../constants";
const Image = (props) => {
  return (
    <div>
      <div className="contenedor-image">
        <p
          onClick={() => {
            props.setImageModal(false);
          }}
          className="closed"
        >
          X
        </p>
        <div className="image-modal">
          <img
            className="img-m"
            src="/assets/Principal/img_depa-tipo_velatowers.png"
          ></img>
        </div>
      </div>
    </div>
  );
};
export default Image;
