import React from "react";
import "./Video.scss";
const Video = (props) => {
  return (
    <div>
      <div className="contenedor_modal_video">
        <p
          onClick={() => {
            props.setVideo(false);
          }}
          className="closed"
        >
          X
        </p>
        <iframe
          src={`https://www.youtube.com/embed/${props.urlVideo}?autoplay=1&mute=1`}
        ></iframe>
      </div>
    </div>
  );
};

export default Video;
