import { placeholderAsset } from "../../constants";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/core";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "swiper/css";
import "swiper/css/pagination";
import "./EspacioIni.scss";

const EspaciosIni = (props) => {
  const images = [
    "/assets/Principal/img_1_sala_velatowers.jpg",
    "/assets/Principal/img_2_comedor_velatowers.jpg",
    "/assets/Principal/img_3_cocina_velatowers.jpg",
    "/assets/Principal/img_4_recamara_velatowers.jpg",
    "/assets/Principal/img_5_recamara2_velatowers.jpg",
  ];
  return (
    <>
      <div className="contenedor-slider">
        <div className="contenedor-izquierdo">
          <Swiper
            loop={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            modules={[Pagination, Autoplay]}
            className="swiper-slider"
          >
            {images.map((image, index) => (
              <SwiperSlide className="swiperslideV" key={index}>
                <img className="imag" src={image} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="contenedor-derecho">
          <AnimationOnScroll
            duration={2}
            animateOnce={true}
            animateIn="animate__fadeIn"
          >
            <div className="contenedor-titulo-slider">
              <h2 className="titulo">
                ESPACIOS <br />
                INIGUALABLES
              </h2>
            </div>
          </AnimationOnScroll>
          <div className="parrafo-slider">
            <p>
              Vela Towers fusiona el diseño contemporáneo en espacios con
              estilo, vangardia y funcionales.
            </p>
          </div>
          <div className="contenedor_video__left_button">
            <a
              onClick={() => {
                props.setImageModal(true);
              }}
            >
              VER DEPARTAMENTO TIPO
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default EspaciosIni;
