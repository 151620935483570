import React from "react";
// Import Swiper React components
import { Swiper } from "swiper/react";
import "./Slideer.scss";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
  EffectFade,
} from "swiper/core";

// install Swiper modules
SwiperCore.use([Pagination, Autoplay, Navigation, EffectFade]);
const Slider = (props) => {
  const { children } = props;
  return (
    <>
      <Swiper
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        effect={"fade"}
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={props.nav}
        className="mySwiper"
      >
        {children}
      </Swiper>
    </>
  );
};

export default Slider;
