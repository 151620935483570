import React, { useState, useEffect } from "react";
import Principal from "../Principal/Principal";
import Disponibilidad from "../Disponibilidad/Disponibilidad";
import Ubicacion from "../ubicacion/Ubicacion";
import ConceptoVideo from "../ConceptoVideo/ConceptoVideo";
import Slider from "../Slider/Slider";
import { SwiperSlide } from "swiper/react";
import { animateScroll as scroll, scrollSpy, scroller } from "react-scroll";
import { useNavigate, useLocation } from "react-router-dom";
import "./Home.scss";
import Section360 from "../Section360/Section360";
import EspaciosInigualables from "../EspaciosInigualables/EspaciosIni";
import Amenidades from "../Amenidades/Amenidades";

const Home1 = (props) => {
  const navigate = useNavigate();
  const actualUrl = useLocation();

  const scrollTarget = (target) => {
    console.log("scroll", target);
    scroller.scrollTo(target, { smooth: true, duration: 700 });
  };

  const scrollToPage = (target) => {
    console.log("app", target);
    navigate(`/${target}`);
    scrollTarget(target.slice(1));
  };
  useEffect(() => {
    scrollToPage(actualUrl.hash);
  }, []);
  return (
    <>
      <div>
        <Principal />
        <ConceptoVideo
          setVideo={props.setVideo}
          setUrlVideo={props.setUrlVideo}
        />
        <Section360 />
        <EspaciosInigualables setImageModal={props.setImageModal} />
        <Disponibilidad />
        <Amenidades />
        <Ubicacion />
      </div>
    </>
  );
};
export default Home1;
