import React from "react";
import "./Amenidades.scss";
import Slider from "../Slider/Slider";
import { SwiperSlide } from "swiper/react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
const Amenidades = () => {
  return (
    <>
      <div id="amenidades" className="contenedor_amenidades">
        <div className="amenidades_top">
          <AnimationOnScroll
            duration={2}
            className="amen"
            animateOnce={true}
            animateIn="animate__fadeIn"
          >
            <div className="amenida">
              <div className="subtitulo_ame">
                <h2>
                  AMENIDADES <br /> EXCLUSIVAS
                </h2>
              </div>
            </div>
            <div>
              <p className="p-amenid">
                Disfruta de los más bellos atardeceres en el ROOF TOP con
                asadores y áreas recreativas para disfrutar de las mejores
                vistas de la ciudad.
              </p>
            </div>
          </AnimationOnScroll>
          <div className="list_ame">
            <div className="colum_list">
              <img src="/assets/Iconos/svg_lobby_velatowers.svg" alt="Lobby" />
              <p>Lobby</p>
            </div>
            <div className="colum_list">
              <img src="/assets/Iconos/svg_gimnasio_velatowers.svg" alt="Gym" />
              <p>Gimnasio</p>
            </div>
            <div className="colum_list">
              <img
                src="/assets/Iconos/svg_asadores_velatowers.svg"
                alt="Asadores"
              />
              <p>Área lounge con fogata y asadores</p>
            </div>
            <div className="colum_list">
              <img
                src="/assets/Iconos/svg_coworking_velatowers.svg"
                alt="Coworking"
              />
              <p>Coworking</p>
            </div>
            <div className="colum_list">
              <img
                src="/assets/Iconos/svg_terraza_velatowers.svg"
                alt="Terraza"
              />
              <p>Terraza</p>
            </div>
            <div className="colum_list">
              <img
                src="/assets/Iconos/svg_estacionamiento_velatowers.svg"
                alt="Estacionamiento"
              />
              <p>Estacionamiento techado</p>
            </div>
            <div className="colum_list">
              <img
                className="alberca"
                src="/assets/Iconos/svg_alberca_velatowers.svg"
                alt="Alberca"
              />
              <p>Alberca</p>
            </div>
            <div className="colum_list">
              <img
                src="/assets/Iconos/svg_acceso-controlado_velatowers.svg"
                alt="Acceso controlado"
              />
              <p>Acceso controlado</p>
            </div>
          </div>
        </div>
      </div>
      <div id="galeria" className="amenidades_bottom">
        <Slider nav={false} pag={true}>
          <SwiperSlide>
            <div className="contenedor__amenidades_carousel__slider">
              <img
                src="/assets/Principal/img_1-alberca_velatowers.png"
                alt="Velasur"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="contenedor__amenidades_carousel__slider">
              <img
                src="/assets/Principal/img_2-terraza_velatowers.jpg"
                alt="Velasur"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="contenedor__amenidades_carousel__slider">
              <img
                src="/assets/Principal/img_3-terraza-convivencia_velatowers.jpg"
                alt="Velasur"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="contenedor__amenidades_carousel__slider">
              <img
                src="/assets/Principal/img_4-coworking_velatowers.jpg"
                alt="Velasur"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="contenedor__amenidades_carousel__slider">
              <img
                src="/assets/Principal/img_5-estacionamiento_velatowers.jpg"
                alt="Velasur"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="contenedor__amenidades_carousel__slider">
              <img
                src="/assets/Principal/img_6-fogateros_velatowers.jpg"
                alt="Velasur"
              />
            </div>
          </SwiperSlide>
        </Slider>
      </div>
    </>
  );
};

export default Amenidades;
