import React from "react";
import "./Ubicacion.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
const Ubicacion = (props) => {
  const handleVideo = () => {
    props.setVideo(true);
    props.setUrlVideo("nNg9KYR4qQs");
  };
  return (
    <div className="contenedor_ubicacion">
      <div className="contenedor_ubicacion__left">
        <AnimationOnScroll
          duration={2}
          animateOnce={true}
          animateIn="animate__fadeIn"
        >
          <div className="contenedor_ubicacion__left_subtitulo">
            <h2>
              UBICACIÓN <br /> PRIVILEGIADA
            </h2>
          </div>
        </AnimationOnScroll>
        <div className="contenedor_ubicacion__left_texto">
          <p>
            Vela Towers se encuentra en una ubicación privilegiada en Ciudad
            Juárez, ofreciendo una vista espectacular de la ciudad y sus
            alrededores. Brinda fácil acceso a importantes centros comerciales,
            restaurantes, parques y servicios públicos.
          </p>
        </div>
        <div className="contenedor_ubicacion__left_button">
          <a href="#ubicacion">VER UBICACIÓN</a>
        </div>
      </div>
      <div className="contenedor_ubicacion__right">
        {/* <img className='play' src="/assets/Logos/boton-de-play.png" alt="" onClick={()=>handleVideo()}/> */}
        <div className="contenedor_ubicacion__right_video">
          <img
            src="/assets/Principal/img_ciudad-jurez_velatowers.jpg"
            autoPlay
            muted
            loop
          ></img>
        </div>
      </div>
    </div>
  );
};

export default Ubicacion;
