import React, { useState } from "react";
import "./Mapa.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import MapBox from "../MapBox/MapBox";

const Mapa = (props) => {
  const destinations = {
    ORIGEN: {
      lat: 20.509106231909826,
      lng: -100.40774736222625,
    },
  };
  let [selectdestination, setDestination] = useState(null);
  return (
    <div id="ubicacion" className="contenedor_mapa">
      <div className="mapa">
        <img src="/assets/Principal/img_ubicacion_velatowers.jpg" />
      </div>
      <div className="ubicaciones">
        <div className="top">
          <AnimationOnScroll
            duration={2}
            animateOnce={true}
            animateIn="animate__fadeIn"
          >
            <div className="top_margin">
              <div className="top_subtitulo">
                <h2>
                  CERCA DE <br /> TODO
                </h2>
              </div>
            </div>
          </AnimationOnScroll>
          <div className="top_texto">
            <p>Ejército Nacional S/N C.P.: 32617,</p>
            <p>Ave. Ejército Nacional, 32617 Juárez, Chih.</p>
          </div>
        </div>
        <div className="bottom">
          <div className="bottom_ubicaciones">
            <div className="row_list">
              <p>A 3 minutos de Plaza Las Misiones</p>
            </div>
            <div className="row_list">
              <p>A 3 minutos del Consulado</p>
            </div>
            <div className="row_list">
              <p>A 5 minutos de Star médica</p>
            </div>
            <div className="row_list">
              <p>A 15 minutos del puente de Zaragoza</p>
            </div>
            <div className="row_list">
              <p>A 20 minutos del aeropuerto</p>
            </div>
          </div>
          <div className="mapas">
            <a
              target={"_blank"}
              href="https://maps.app.goo.gl/38oqDZ2eBgx3v4cd7"
            >
              <img src="/assets/Logos/svg-icon-maps.svg" alt="" />
            </a>
            <a
              target={"_blank"}
              href="https://ul.waze.com/ul?ll=31.69248755%2C-106.40995860&navigate=yes&zoom=17&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"
            >
              <img src="/assets/Logos/svg-icon-waze.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mapa;
