import React, { useEffect } from "react";
import "./Galeria.scss";
const Galeria = (props) => {
  const handleShowModalGaleria = (tipo, url) => {
    props.setTypeGaleria(tipo);
    props.setUrlGaleria(url);
    props.setOpenGaleria(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="contenedor_galeria">
      <a href="/">
        <p className="closed_gallery">X</p>
      </a>
      <div className="grid">
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria(
              "img",
              "/assets/galeria/gallery/img-cocina.jpg"
            )
          }
        >
          <img src="/assets/galeria/gallery/img-cocina.jpg" alt="" />
          <div className="caption">
            <p>Cocina</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria(
              "img",
              "/assets/galeria/gallery/img-comedor-punta_vela.jpg"
            )
          }
        >
          <img
            src="/assets/galeria/gallery/img-comedor-punta_vela.jpg"
            alt=""
          />
          <div className="caption">
            <p>Comedor</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria(
              "img",
              "/assets/galeria/gallery/img-fachada2-punta_vela.jpg"
            )
          }
        >
          <img
            src="/assets/galeria/gallery/img-fachada2-punta_vela.jpg"
            alt=""
          />
          <div className="caption">
            <p>Fachada</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria(
              "img",
              "/assets/galeria/gallery/img-fachada-punta_vela.jpg"
            )
          }
        >
          <img
            src="/assets/galeria/gallery/img-fachada-punta_vela.jpg"
            alt=""
          />
          <div className="caption">
            <p>Fachada</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria(
              "img",
              "/assets/galeria/gallery/img-recamara-punta_vela.jpg"
            )
          }
        >
          <img
            src="/assets/galeria/gallery/img-recamara-punta_vela.jpg"
            alt=""
          />
          <div className="caption">
            <p>Recámara</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria(
              "img",
              "/assets/galeria/gallery/img-recamara-punta_vela-1.jpg"
            )
          }
        >
          <img
            src="/assets/galeria/gallery/img-recamara-punta_vela-1.jpg"
            alt=""
          />
          <div className="caption">
            <p>Recámara</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria(
              "img",
              "/assets/galeria/gallery/img-sala-punta_vela.jpg"
            )
          }
        >
          <img src="/assets/galeria/gallery/img-sala-punta_vela.jpg" alt="" />
          <div className="caption">
            <p>Sala</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria(
              "img",
              "/assets/galeria/gallery/img-terraza2-punta_vela.jpg"
            )
          }
        >
          <img
            src="/assets/galeria/gallery/img-terraza2-punta_vela.jpg"
            alt=""
          />
          <div className="caption">
            <p>Terraza</p>
          </div>
        </div>
        <div
          className="img-grid"
          onClick={() =>
            handleShowModalGaleria(
              "img",
              "/assets/galeria/gallery/img-terraza-punta_vela.jpg"
            )
          }
        >
          <img
            src="/assets/galeria/gallery/img-terraza-punta_vela.jpg"
            alt=""
          />
          <div className="caption">
            <p>Terraza</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Galeria;
