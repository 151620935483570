import React, { useState, useEffect, useCallback } from "react";
import "./Principal.scss";
import { placeholderAsset } from "../../constants";
import Slider from "../Slider/Slider";
import { SwiperSlide } from "swiper/react";

const Principal = () => {
  const [y, setY] = useState(window.scrollY);
  const [showStiky, setShowStiky] = useState("ocultar");

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        if (window.scrollY <= 100) {
          setShowStiky("ocultar");
          console.log(y, "y");
          console.log(window.scrollY, "scrolly");
        }
      } else if (y < window.scrollY) {
        if (window.scrollY >= 100) {
          setShowStiky("mostrar");
          console.log(y, "y");
          console.log(window.scrollY, "scrolly");
        }
      }
      setY(window.scrollY);
    },
    [y]
  );
  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <div className="contenedor__principal">
      <div className="contenedor__principal_carousel">
        <div className="contenedor__principal_carousel__slider">
          <img
            src="/assets/Principal/img_hero_velatowers.jpg"
            alt="Velasur exterior"
          />
        </div>
      </div>
      <div className="bottom_info">
        <div className="icon-scroll"></div>
        <div className="whatsapp">
          <div className={showStiky}>
            <a target={"_blank"} href="https://wa.me/+526566385985">
              <img src="/assets/Logos/svg-icon-whatsapp.svg" alt="Whatsapp" />
            </a>
          </div>
        </div>
        <div className="texto">
          <p>DEPARTAMENTOS RESIDENCIALES PREMIUM</p>
        </div>
      </div>
    </div>
  );
};

export default Principal;
