import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import "./ConceptoVideo.scss";
const ConceptoVideo = (props) => {
  const handleVideo = () => {
    props.setVideo(true);
    props.setUrlVideo("-bOX52M4AKo");
  };
  return (
    <div id="concepto" className="contenedor_video">
      <div className="contenedor_video__left">
        <AnimationOnScroll
          duration={2}
          animateOnce={true}
          animateIn="animate__fadeIn"
        >
          <div className="contenedor_video__left_subtitulo">
            <h2 className="titulo">
              DESCUBRE <br />
              VELATOWERS
            </h2>
          </div>
        </AnimationOnScroll>
        <div className="contenedor_video__left_texto">
          <p className="space">
            <strong className="strong">Velatowers</strong>, un estilo de vida
            ÚNICO; Lujo, seguridad y sofisticación, brindando una experiencia
            sin igual en la ciudad.
          </p>
          <p>
            <strong className="strong">Velatowers</strong>, tiene 8 niveles y 32
            exclusivos departamentos con vista PANORÁMICA.
          </p>
        </div>
        <div className="contenedor_video__left_button">
          <a target="_blank" href={process.env.REACT_APP_AVAILABILITY_URL}>
            VER DISPONIBILIDAD
          </a>
        </div>
      </div>
      <div className="contenedor_video__right">
        <img
          className="play"
          src="/assets/Logos/boton-de-play.png"
          alt=""
          onClick={() => handleVideo()}
        />
        <div className="contenedor_video__right_video">
          {/* <img src="https://via.placeholder.com/993x559/" alt="" /> */}
          <img
            className="img-concepto-video"
            src="/assets/Principal/vid-intro-velatowers.gif"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default ConceptoVideo;
