import React, { useEffect, useRef } from "react";
import "./Disponibilidad.scss";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Disponibilidad = () => {
  return (
    <div className="contenedor_disponibilidad">
      <div className="lugares-cercanos">
        <div className="dispo_top">
          <AnimationOnScroll
            duration={2}
            animateOnce={true}
            animateIn="animate__fadeIn"
          >
            <div className="subtitulo_dispo">
              <h2>VIVE ALTO</h2>
            </div>
          </AnimationOnScroll>
          <div className="texto_dispo">
            <h2>CARACTERÍSTICAS</h2>
            <p>
              Los departamentos de Vela Towers ofrecen una experiencia de vida
              incomparable, con una variedad de características excepcionales
              que brindan comodidad y sofisticación a sus residentes.
            </p>
          </div>
        </div>
        <div className="lugares_dispo">
          <div className="lugares_dispo_p">
            <AnimationOnScroll
              className="height-full"
              duration={2}
              animateOnce={true}
              animateIn="animate__fadeIn"
            >
              <div className="row_lugar">
                <li>
                  162 m<sup>2</sup>
                </li>
                <li>3 recámaras</li>
                <li>2.5 baños</li>
                <li>Cuarto de lavado</li>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll
              className="height-full"
              duration={2}
              animateOnce={true}
              animateIn="animate__fadeIn"
            >
              <div className="row_lugar">
                <li>Cocina</li>
                <li>Sala-Comedor</li>
                <li>Terraza</li>
              </div>
            </AnimationOnScroll>
          </div>
          <div className="button_disponibilidad">
            <a target="_blank" href={process.env.REACT_APP_AVAILABILITY_URL}>
              DISPONIBILIDAD
            </a>
          </div>
        </div>
      </div>
      <div id="masterplan" className="bottom_mapa">
        <div className="masterplan-text">
          <h2>DISPONIBILIDAD</h2>
          <div className="button_depto_disp">
            <a target="_blank" href={process.env.REACT_APP_AVAILABILITY_URL}>
              VER DEPARTAMENTOS
            </a>
          </div>
        </div>
        <div>
          <img src="/assets/Principal/img_fachada_velatowers.jpg" />
        </div>
      </div>
    </div>
  );
};

export default Disponibilidad;
