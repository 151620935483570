import React from "react";
import "./Footer.scss";
const Footer = (props) => {
  return (
    <div id="contacto" className="contenedor_footer">
      <div className="contenedor_footer_social">
        <a href="https://www.facebook.com/VelaTowersJuarez" target={"_blank"}>
          <img src="/assets/Logos/svg-icon-facebook.svg" alt="" />
        </a>
        <a href="https://www.instagram.com/velatowersjuarez" target={"_blank"}>
          <img src="/assets/Logos/svg-icon-instagram.svg" alt="" />
        </a>
      </div>
      <div className="contenedor_footer_logo">
        <a href="https://grupovelas.com.mx/" target={"_blank"}>
          <img src="/assets/Logos/svg-logo-grupo-velas.svg" alt="" />
        </a>
      </div>
      <div className="contenedor_footer_tel">
        <a href="tel:+526566385985" target={"_blank"}>
          T. (656) 638 5985
        </a>
      </div>
      <div className="contenedor_footer_info">
        <p>
          {" "}
          © 2024 Grupo Velas ® Todos los derechos reservados |{" "}
          <a
            onClick={() => {
              props.setModalAviso(true);
            }}
          >
            Política de privacidad
          </a>
        </p>
        <p>
          El vendedor de Vela Towers se reserva el derecho de realizar
          modificaciones en los planos, diseños exteriores, precios, materiales,
          especificaciones, acabados y características estándar en cualquier
          momento y sin previo aviso. Las fotografías, las representaciones son
          ilustrativos y conceptuales.
        </p>
        <div>
          <p>Diseño web por:</p>
          <a href="https://renderinc.mx" target={"_blank"}>
            <img src="/assets/Logos/svg-logo-renderinc.svg" alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
