import "./Section.scss";
const Section360 = () => {
  return (
    <div className="iframe" id="recorrido">
      <iframe
        title="Tour 360"
        className="iframe-video"
        src="https://kuula.co/share/collection/7Xrr4?logo=-1&info=0&fs=1&vr=0&zoom=0&sd=1&initload=1&autop=10&thumbs=1&margin=6&alpha=0.60&inst=es"
        width="100%"
        height="100%"
      />
    </div>
  );
};
export default Section360;
